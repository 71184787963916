module.exports = {
  pathPrefix: '/', // Prefix for all links. If you deploy your site to example.com/portfolio your pathPrefix should be "portfolio"
  title: 'CENTRUMSPED s.r.o.', // Navigation and Site Title
  titleAlt: 'CENTRUMSPED s.r.o.', // Title for JSONLD
  description: 'EXHIBITION LOGISTICS WORLDWIDE',
  headline: 'EXHIBITION LOGISTICS WORLDWIDE', // Headline for schema.org JSONLD
  url: 'https://centrumsped.com', // Domain of your site. No trailing slash!
  logo: 'static/logos/logo.jpg', // Used for SEO
  ogLanguage: 'en_US', // Facebook Language

  // JSONLD / Manifest
  favicon: 'static/logos/favicon.png', // Used for manifest favicon generation
  shortName: 'Centrumsped', // shortname for manifest. MUST be shorter than 12 characters
  author: 'Kristyna Dierstein', // Author for schemaORGJSONLD
  themeColor: '#3D63AE',
  backgroundColor: '#EBEDF2',

  googleAnalyticsID: 'UA-XXXXXX-X',

  skipNavId: 'reach-skip-nav', // ID for the "Skip to content" a11y feature
}
