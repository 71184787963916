/* eslint react/prop-types: 0, react/display-name: 0 */
import React from "react";
import { LocaleProvider } from "./src/context/LocaleContext";


import { Layout } from "./src/components/Layout";

const wrapPageElement = ({ element, props }) => {
  return (
    <LocaleProvider locale={props.pageContext.locale}>
        <Layout {...props}>{element}</Layout>
    </LocaleProvider>
  );
};

export default wrapPageElement;
