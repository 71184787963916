import React from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { theme } from "../styles";

const StyledFooter = styled.footer`
  width: 100%;

  p {
    margin-bottom: 0px;
    text-align: center;
    font-size: 12 !important;
  }
  .footer {
    height: 205px;
    background-color: #133c8b;
  }

  .top-footer {
    background-color: #f7f6fa;
    padding-top: 21px;
    padding-bottom: 15px;
    color: ${theme.colors.primary};
  }

  .top-footer p {
    color: ${theme.colors.primary};
    margin-bottom: 0px;
    text-align: center;
    font-size: 10px !important;
    width: 60%;
    margin: 0 auto;
  }

  .bottom-footer {
    height: 50%;
    padding-top: 20px;
  }

  .bottom-footer p {
    color: white;
    padding-bottom: 10px;
    width: 60%;
    margin: 0 auto;
    font-size: 10px !important;
    text-transform: none;
  }
  .bottom-footer a {
    color: white !important;
  }

  @media (min-width: 1025px) {
    .top-footer {
      height: auto;
    }

    .top-footer p {
      margin: 0 auto;
    }

    .bottom-footer {
      height: 99px;
    }

    .bottom-footer p {
      margin: 0 auto;
    }

    .bottom-footer a {
      color: white !important;
    }
  }
`;

const Footer = ({ children }) => <StyledFooter>{children}</StyledFooter>;

export default Footer;

// Footer.propTypes = {
//   children: PropTypes.node.isRequired,
// };
