/* eslint no-unused-expressions: 0 */
/* eslint react/destructuring-assignment: 0 */

import React from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql, Link } from "gatsby";
import { Global, css } from "@emotion/core";
import styled from "@emotion/styled";
import { ThemeProvider } from "emotion-theming";
import "@reach/skip-nav/styles.css";
import CzeckFlag from "../images/Czech_flag.svg";
import BritainFlag from "../images/800px-Flag_of_Great_Britain_(1707–1800) 1.svg";

import Footer from "./Footer";
import SkipNavLink from "./SkipNavLink";
import { theme, reset } from "../styles";
import i18n from "../../config/i18n";

const globalStyle = css`
  ${reset}

  h1, h2, h3, h4, h5, h6, p {
    color: ${theme.colors.primary};
    margin-bottom: 0;
  }

  html {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: ${theme.fontFamily.primary};
    /* overflow: hidden; */
  }
  body {
    color: ${theme.colors.greyDarker};
    background-color: ${theme.colors.bg};
    font-family: ${theme.fontFamily.primary};
    overflow: hidden;
  }

  button:focus {
    outline: none;
  }

  a[href^="tel"]{
    color:inherit;
    text-decoration:none;
}
a:link {
  text-decoration: none;
}

  ::selection {
    color: ${theme.colors.bg};
    background-color: ${theme.colors.primary};
  }
  a {
    color: ${theme.colors.primary};
    text-decoration: none!important;
    font-weight: 300;
    // padding-right: 10px;
    font-style: normal;
    font-family: ${theme.fontFamily.primary};


    &:hover,
    &:active {
      border-bottom: none;
      background: linear-gradient(#133c8b, #133c8b) bottom center
        /* left or right or else */ no-repeat;
      background-size: 35% 3px;
      padding-bottom: 10px;
    }
    &:focus {
      text-decoration: none;
      border: none;
    }
  }

  .ReactModal__Body--open {
    overflow: hidden;
    position: fixed;
    width: 100%;
    height: 100%;
  }

  .scroll-up-button{
    background-color: ${theme.colors.primary}!important;
    opacity: .7;

    @media (max-width: ${theme.breakpoints.s}) {
      width: 30px!important;
      height: 30px!important;
    }

    :focus {
      svg {
        outline: none!important;
      }

    }
  }

  @media (min-width: ${theme.breakpoints.xs}) {
    // html {
    //   font-size: 24px !important;
    // }
  }
  @media (max-width: ${theme.breakpoints.s}) {
    h1 {
      font-size: 26px !important;
    }
    p {
      font-size: 14px !important;
    }
    ol {
      font-size: 14px !important;
    }
    h2 {
      font-size: 24px !important;
    }
    h3 {
      font-size: 16px !important;
    }
    h4 {
      font-size: 14px !important;
    }
    h5 {
      font-size: 14px !important;
    }
    h6 {
      font-size: 14px !important;
    }
  }

  @media (min-width: ${theme.breakpoints.s}) {
    h1 {
      font-size: 72px !important;
    }
    h2 {
      font-size: 24px !important;
    }
    h3 {
      font-size: 28px !important;
    }
    p {
      font-size: 16px !important;
    }
    ol p {
      font-size: 16px !important;
    }
    h4 {
      font-size: 18px !important;
    }
    h5 {
      font-size: 18px !important;
    }
    h6 {
      font-size: 16px !important;
    }
  }
`;

const LocaleSwitcher = styled.div`
  position: absolute;
  top: 22px;
  right: 45px;

  a:hover {
    background: none;
  }

  img{
    margin-left:20px;
  }

  @media (max-width: 1210px) {
    display: none;
  }
`;

const LocaleContext = React.createContext();

const Layout = ({ children, pageContext: { locale } }) => {
  const data = useStaticQuery(query);
  console.log({locale});

  return (
    <LocaleContext.Provider value={{ locale, i18n }}>
      <ThemeProvider theme={theme}>
        <>
          <Global styles={globalStyle} />
          <SkipNavLink />
          {children}
          <Footer></Footer>
        </>
      </ThemeProvider>
    </LocaleContext.Provider>
  );
};

export { LocaleContext, Layout, LocaleSwitcher };

const query = graphql`
  query LayoutQuery {
    allPrismicHomepage {
      edges {
        node {
          lang
        }
      }
    }
  }
`;

Layout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.node]).isRequired,
  pageContext: PropTypes.shape({
    locale: PropTypes.string.isRequired,
  }).isRequired,
};
