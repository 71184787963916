const website = require("./website");

module.exports = {
  "cs-cz": {
    default: true,
    path: "cz",
    locale: "cs-cz",
    siteLanguage: "cz",
    ogLang: "cs-cz",
    defaultTitle: website.title,
    defaultTitleAlt: website.titleAlt,
    defaultDescription:
      "CENTRUMSPED s.r.o. - EXHIBITION LOGISTICS WORLDWIDE",
    headline: "CENTRUMSPED s.r.o. - EXHIBITION LOGISTICS WORLDWIDE",
    category: "Kategorie",
    categories: "Kategorien",
    was: "wurde",
    were: "wurden",
    tagged: "markiert mit",
    recent: "Neue",
    projects: "Projekte",
    allCategories: "Alle Kategorien",
    entries: "Einträge",
  },
  "en-us": {
    path: "en",
    locale: "en-us",
    siteLanguage: "en",
    ogLang: "en_US",
    defaultTitle: website.title,
    defaultTitleAlt: website.titleAlt,
    defaultDescription: website.description,
    headline: website.headline,
    category: "Category",
    categories: "Categories",
    was: "was",
    were: "were",
    tagged: "tagged with",
    recent: "Recent",
    projects: "projects",
    allCategories: "All categories",
    entries: "entries",
  },
};
